// Styles
import '../scss/main.scss';

// Scripts
import { offer } from '@shop/frontend/dist/desktop/order-full-form';
import '/vendor/js/slick.js';
import './custom/social-proof.js';

$(document).ready(function () {
  offer.getState(function (data) {
    const cmp = data.query.cmp;
    const currency = offer.getCurrency();
    if (cmp === 'false') {
      $('.cmp-show').show();
    }

    const save = document.querySelectorAll('.save');
    save.forEach((el, i) => {
      el.innerHTML = currency + data.availableProducts[i].templates.save;
    });
  });

  let time = 300,
    timer,
    minutes,
    seconds;

  timer = setInterval(function () {
    if (time > 0) {
      time--;
      minutes = (time / 60) | 0;
      seconds = time % 60 | 0;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      $('.time').html(minutes + ':' + seconds);
    } else {
      time = 300;
    }
  }, 1000);

  $('.slider').slick({
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3600,
  });
});
